import { ErrorHandler, Injectable } from '@angular/core';

declare let newrelic:
  | {
      noticeError(error: unknown): void;
    }
  | undefined;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    if (typeof newrelic !== 'undefined') {
      newrelic.noticeError(error);
    }
  }
}

export const GlobalErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};
