import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TofeCustomization } from 'top-api-sdk-angular';
import { merge } from 'ts-deepmerge';

import { DefaultCustomization } from './customization.constants';

const LOG_PREFIX = '[Service][CustomizationLoader] ';
const LOG_ENABLED = true;

@Injectable({
  providedIn: 'root',
})
export class CustomizationLoaderService {
  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
  ) {}

  getCustomization(name: string): Observable<TofeCustomization> {
    if (name === 'default') return of(DefaultCustomization);
    return this.http.get<TofeCustomization>(`assets/customizations/${name}.json`).pipe(
      map((x) => merge(DefaultCustomization, x)),
      catchError((err) => {
        if (LOG_ENABLED) this.logger.error(`${LOG_PREFIX}current$ http error`, err);
        return of(DefaultCustomization);
      }),
    );
  }
}
