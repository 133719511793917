import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { TranslocoService } from '@jsverse/transloco';
import { HashMap, TranslateParams } from '@jsverse/transloco/lib/types';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';

import { LanguageConfigurationService } from './language-configuration.service';

const LOG_PREFIX = '[Service][Language] ';
const LOG_ENABLED = true;

function updateQueryParam(key: string, value: string) {
  if (!window.location.href) return;
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const searchParams = url.searchParams;
  if (searchParams.get(key) === value) return;
  searchParams.set(key, value);
  url.search = searchParams.toString();
  window.history.replaceState({}, '', url);
}

@Injectable()
export class LanguageService {
  translateIsReady$ = new BehaviorSubject<boolean>(false);
  current$: Observable<string>;
  private currentSubject = new BehaviorSubject<string>('');

  constructor(
    private logger: NGXLogger,
    private transloco: TranslocoService,
    private conf: LanguageConfigurationService,
  ) {
    this.current$ = this.currentSubject.asObservable().pipe(
      filter((x) => !!x),
      tap((y) => {
        if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}using ${y}`);
      }),
      switchMap((lang) => {
        if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}current$ activating transloco`, lang);
        this.transloco.setActiveLang(lang);
        return this.transloco.selectTranslation(lang).pipe(map(() => lang));
      }),
      tap((y) => {
        this.translateIsReady$.next(true);
        Preferences.set({ key: 'tofe-language', value: y });
        updateQueryParam('lang', y);
        if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}current$`, y);
      }),
      shareReplay(1),
    );
  }

  init(): Observable<string> {
    return this.current$.pipe(take(1));
  }

  getCurrent(): string {
    return this.currentSubject.getValue();
  }

  getCurrentLocale(): string {
    const curr = this.getCurrent();
    if (curr === 'en') return 'en-US';
    return curr.toLowerCase() + '-' + curr.toUpperCase();
  }

  setCurrent(lang: string) {
    if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}setCurrent`, lang);
    return this.currentSubject.next(this.filterAvailableLanguage(lang));
  }

  translate<T = string>(key: TranslateParams, params?: HashMap, lang?: string): T {
    return this.transloco.translate(key, params, lang);
  }

  translateObject<T = unknown>(
    key: string[] | TranslateParams,
    params?: HashMap | null | undefined,
    lang?: string,
  ): T | T[] {
    if (params === null) {
      params = undefined;
    }
    return this.transloco.translateObject<T>(key, params, lang);
  }

  private filterAvailableLanguage(x: string) {
    if (this.conf.getAvailableLanguages().some((language) => language === x)) {
      return x;
    }
    const def = this.conf.getDefaultLanguage();
    if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}language "${x}" not allowed, using default "${def}"`);
    return def;
  }
}
