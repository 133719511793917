import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { TofeCustomization } from 'top-api-sdk-angular';

import { CustomizationService } from './customization.service';

const LOG_PREFIX = '[Resolver][Customization]';
const LOG_ENABLED = true;

export const customizationResolver: ResolveFn<TofeCustomization> = (route) => {
  const logger = inject(NGXLogger, { optional: true });
  const service = inject(CustomizationService);
  const customization = route.queryParamMap.get('customization') as string;
  if (LOG_ENABLED && logger) logger.debug(LOG_PREFIX + ' hit', customization);
  return service.setCurrent(customization);
};
