import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable } from 'rxjs';

import { LanguageConfigurationService } from './language-configuration.service';

@Injectable()
export class HttpLoaderService implements TranslocoLoader {
  constructor(private loader: LanguageConfigurationService) {}

  getTranslation(lang: string): Observable<Translation> {
    return this.loader.getTranslation(lang);
  }
}
