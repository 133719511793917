import { environmentCommon } from './environment-common';
import { AppEnvironment } from './types';

const environmentMap: {
  [key: string]: Partial<AppEnvironment>;
} = {
  'toponboarding-test.infocert.it': {
    environment: 'svts',
    apiBaseUrl: 'https://top.eu-west-1.tsaws.infocert.it/top/rest10',
  },
  'toponboarding-stage.infocert.it': {
    environment: 'stage',
    apiBaseUrl: 'https://top-stage.infocert.it/top/rest10',
  },
  'toponboarding.infocert.it': {
    environment: 'prod',
    apiBaseUrl: 'https://top-cloud.infocert.it/top/rest10',
  },
  default: {
    environment: 'local',
    apiBaseUrl: localStorage.getItem('apiBaseUrl') || 'https://localhost:4200/api',
  },
};

export const environment: AppEnvironment = {
  ...environmentCommon,
  production: true,
  ...(environmentMap[window.location.hostname] || environmentMap['default']),
};
