/**
 * TOP Frontend
 * This is an **example** API to demonstrate features of the OpenAPI specification.  # Introduction  This API definition is intended to be a good starting point for describing your API in   [OpenAPI/Swagger format](https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.2.md).  It also demonstrates features of the [create-openapi-repo](https://github.com/Redocly/create-openapi-repo) tool and   the [Redoc](https://github.com/Redocly/Redoc) documentation engine. Beyond the standard OpenAPI syntax, we use a few   [vendor extensions](https://github.com/Redocly/Redoc/blob/master/docs/redoc-vendor-extensions.md).   # OpenAPI Specification  The goal of The OpenAPI Specification is to define a standard, language-agnostic interface to REST APIs which  allows both humans and computers to discover and understand the capabilities of the service without access to source  code, documentation, or through network traffic inspection. When properly defined via OpenAPI, a consumer can   understand and interact with the remote service with a minimal amount of implementation logic. Similar to what  interfaces have done for lower-level programming, OpenAPI removes the guesswork in calling the service. 
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PersonDataFieldConfiguration { 
    /**
     * The section identifier where the field belongs.
     */
    section?: string;
    /**
     * The name of the field.
     */
    name?: string;
    /**
     * The data type of the field.
     */
    type?: PersonDataFieldConfiguration.TypeEnum;
    /**
     * The visibility condition of the field.
     */
    visibility?: PersonDataFieldConfiguration.VisibilityEnum;
    /**
     * The permissions for the field.
     */
    permissions?: PersonDataFieldConfiguration.PermissionsEnum;
    /**
     * Validation rules for the field.
     */
    validation?: string;
    /**
     * Indicates whether the field is required.
     */
    required?: boolean;
    /**
     * The order of the field in the list.
     */
    sortOrder?: number;
    /**
     * The source of the field data.
     */
    source?: PersonDataFieldConfiguration.SourceEnum;
}
export namespace PersonDataFieldConfiguration {
    export type TypeEnum = 'string' | 'number' | 'date';
    export const TypeEnum = {
        String: 'string' as TypeEnum,
        Number: 'number' as TypeEnum,
        Date: 'date' as TypeEnum
    };
    export type VisibilityEnum = 'HIDE_ALWAYS' | 'HIDE_WHEN_EMPTY';
    export const VisibilityEnum = {
        Always: 'HIDE_ALWAYS' as VisibilityEnum,
        WhenEmpty: 'HIDE_WHEN_EMPTY' as VisibilityEnum
    };
    export type PermissionsEnum = 'RO' | 'RW';
    export const PermissionsEnum = {
        Ro: 'RO' as PermissionsEnum,
        Rw: 'RW' as PermissionsEnum
    };
    export type SourceEnum = 'PERSON_DATA' | 'SIGNER_ADDITIONAL_DATA' | 'DOSSIER_ADDITIONAL_DATA';
    export const SourceEnum = {
        PersonData: 'PERSON_DATA' as SourceEnum,
        SignerAdditionalData: 'SIGNER_ADDITIONAL_DATA' as SourceEnum,
        DossierAdditionalData: 'DOSSIER_ADDITIONAL_DATA' as SourceEnum
    };
}


