import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';
import { LanguageConfigurationService } from 'tofe-language';
import { TofeCustomization } from 'top-api-sdk-angular';

import { DefaultCustomization } from './customization.constants';
import { CustomizationLoaderService } from './customization-loader.service';

const LOG_PREFIX = '[Service][Customization] ';
const LOG_ENABLED = true;

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  readonly current$: Observable<TofeCustomization>;
  private readonly currentSubject = new BehaviorSubject<string>('default');
  private current: TofeCustomization = DefaultCustomization;

  constructor(
    private logger: NGXLogger,
    private conf: LanguageConfigurationService,
    customizationLoader: CustomizationLoaderService,
  ) {
    this.current$ = this.currentSubject.asObservable().pipe(
      filter((x) => !!x),
      tap((y) => {
        if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}using ${y}`);
      }),
      switchMap((name) => customizationLoader.getCustomization(name)),
      tap({
        next: (y) => {
          if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}current$`, y);
          this.initCurrent(y);
        },
        error: (err) => {
          if (LOG_ENABLED) this.logger.error(`${LOG_PREFIX}current$ error`, err);
        },
      }),
      shareReplay(1),
    );
  }

  setCurrent(name?: string): Observable<TofeCustomization> {
    if (LOG_ENABLED) this.logger.debug(`${LOG_PREFIX}setCurrent`, name);
    this.currentSubject.next(name || 'default');
    return this.current$.pipe(take(1));
  }

  getCurrent(): TofeCustomization {
    return this.current;
  }

  initCssVariables(nativeElement: HTMLElement) {
    const style = nativeElement.style;
    const curr = this.getCurrent();
    const cssVariables = (curr.cssVariables as Record<string, string>) || {};
    Object.keys(cssVariables).forEach((key) => {
      style.setProperty(key, cssVariables[key]);
    });
  }

  private initCurrent(current: TofeCustomization) {
    this.current = current;
    const language = current.language;
    if (language) {
      this.conf.setCustomization(language);
    }
  }
}
