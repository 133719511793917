import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StartResponse } from 'top-api-sdk-angular';

@Injectable({
  providedIn: 'root',
})
export class ProcessInResponseService {
  private startResponseSubject = new BehaviorSubject<StartResponse | undefined>(undefined);
  public startResponse$ = this.startResponseSubject.asObservable();

  setStartResponse(startResponse: StartResponse) {
    this.startResponseSubject.next(startResponse);
  }
}
