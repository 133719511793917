import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

/**
 * This interceptor is used to add the browserRef header to all requests.
 * browserRef is a unique identifier for the browser session.
 * when the process starts
 */
@Injectable()
export class BrowserRefInterceptor implements HttpInterceptor {
  private browserRef: string | null = null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private browserId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check if the request URL contains the specific base path, otherwise ignore.
    if (!request.url.includes(environment.apiBaseUrl)) {
      return next.handle(request);
    }

    request = this.cloneRequest(request);

    // Continue with the request.
    return next.handle(request).pipe(
      // Intercept the response and check for the X-BrowserRef header.
      tap((event) => {
        if (event instanceof HttpResponse) {
          const xBrowserRefHeader = event.headers.get('X-Browser-Ref');
          if (xBrowserRefHeader) {
            // Store the X-BrowserRef header for future requests.
            this.browserRef = xBrowserRefHeader;
          }
        }
      }),
    );
  }

  private cloneRequest(request: HttpRequest<unknown>) {
    const setHeaders: { [name: string]: string | string[] } = {
      'X-Browser-Id': this.browserId,
    };
    // If the browserRef is set, add it as a header to the outgoing request.
    if (this.browserRef) {
      setHeaders['X-Browser-Ref'] = this.browserRef;
    }
    return request.clone({ setHeaders, withCredentials: true });
  }
}

export const BrowserRefInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BrowserRefInterceptor,
  multi: true,
};
